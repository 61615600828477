<template>
  <!-- eslint-disable vuejs-accessibility/interactive-supports-focus -->
  <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
  <div
    v-tooltip="{
      theme: 'kog-tooltip',
      content: item.tooltip,
      popperClass: 'text-center',
      boundary: 'document.body',
    }"
    class="KogDropdownItemV2 flexContainer flexContainer-row flexContainer-alignCenter"
    :class="{
      'KogDropdownItemV2--selected': isSelected,
      'KogDropdownItemV2--divider': item.divider,
      [`KogDropdownItemV2--${role}--selectable`]: !isSelected && !isGroupTitle && !isDisabled,
      [`KogDropdownItemV2--${dropdownItemType}`]: true,
      'KogDropdownItemV2--groupTitle': isGroupTitle,
      'KogDropdownItemV2--disabled': isDisabled,
    }"
    @keydown.right.stop="handleKeyRightPressed"
    @keydown.left="handleKeyLeftPressed"
  >
    <div
      :id="item.id"
      ref="KogDropdownItemV2Option"
      class="KogDropdownItemV2-option flexChild-size-1 inlineflexContainer flexContainer-alignCenter"
      :class="{
        'KogDropdownItemV2-option--selectable': !isSelected && !isGroupTitle && !isDisabled,
        'KogDropdownItemV2-option--withChildOptions': hasChildOptions,
      }"
      :role="role"
      :tabindex="tabIndex"
      :aria-selected="isSelected ? 'true' : 'false'"
      :aria-label="item.text"
      @mouseenter.stop="focusOption()"
      @focus="focusOption()"
      @click="onSelect"
      @keydown.enter.prevent="onSelect"
    >
      <kog-icon
        v-if="item.iconClass"
        class="margin-right-s"
        theme="custom"
        :icon-class="item.iconClass"
        :is-svg-icon="!!item.isSvgIcon"
        :is-image-icon="!!item.isImageIcon"
        fa-style="regular"
        size="s"
      />
      <kog-checkbox
        v-if="dropdownItemType === 'checkbox' && !isGroupTitle"
        :label="item.text"
        :is-checked="item.isChecked"
        :is-disabled="isDisabled"
        @toggled="onSelect"
      />
      <span
        v-else
        :class="{
          'text-overline': isGroupTitle,
          'text-regular': !isGroupTitle,
        }"
      >
        {{ item.text }}
      </span>
      <kog-tag
        v-if="item.hasTag"
        v-bind="item.tagProps"
      />
    </div>
    <div
      v-if="hasChildOptions"
      class="flexChild-flexStretch padd-right-m flexContainer flexContainer-row flexContainer-alignCenter"
    >
      <div class="KogDropdownItemV2-separator flexChild-flexStretch" />
      <kog-dropdown-button
        ref="subOptionExpander"
        :aria-label="`Show options for ${item.text}`"
        button-type="round"
        icon-class="fa-chevron-right"
        icon-style="regular"
        :items="item.options"
        :dropdown-item-role="role"
        :is-dropdown-hosted-in-parent-container="true"
        :selected-item="selectedItem"
        dropdown-placement="right-start"
        size="x-small"
        button-style="secondary-basic"
        @select="onSubOptionSelect"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
import { defineAsyncComponent } from 'vue';
import { VTooltip } from 'floating-vue';

import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';

export default {
  name: 'KogDropdownItemV2',
  components: {
    KogIcon,
    KogCheckbox,
    KogTag,
    KogDropdownButton: defineAsyncComponent(
      () =>
        // eslint-disable-next-line import/no-cycle
        import('sharedApp/components/base/buttons/kog-dropdown-button.vue'),
    ),
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: 'option',
      validator: prop => ['option', 'menuitem'].includes(prop),
    },
    dropdownItemType: {
      type: String,
      default: 'text',
      validator: prop => ['text', 'radio', 'checkbox'].includes(prop),
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['select'],
  computed: {
    isDisabled() {
      return this.item.disabled === true;
    },
    isGroupTitle() {
      return this.item.isGroupTitle === true;
    },
    hasChildOptions() {
      return this.item.options && this.item.options.length > 0;
    },
    tabIndex() {
      if (this.isDisabled || this.isGroupTitle || this.dropdownItemType !== 'text') {
        return null;
      }

      if (this.isSelected) {
        return 0;
      }

      return -1;
    },
  },
  methods: {
    onSelect() {
      if (this.isSelected || this.isDisabled || this.isGroupTitle) {
        return;
      }

      this.$emit('select', this.item);
    },
    onSubOptionSelect(item) {
      this.$emit('select', item);
    },
    focusOption() {
      if (this.isDisabled || this.isGroupTitle) {
        return;
      }

      this.$refs.KogDropdownItemV2Option.focus();
    },
    handleKeyRightPressed() {
      if (!this.hasChildOptions) {
        return;
      }

      this.$refs.subOptionExpander.showDropdown();
    },
    handleKeyLeftPressed(keyEvent) {
      if (!this.hasChildOptions) {
        return;
      }

      const { subOptionExpander } = this.$refs;
      if (subOptionExpander.isDropdownOpen) {
        subOptionExpander.closeDropdown();
        keyEvent.stopPropagation();
      }
    },
  },
};
</script>

<style scoped>
.KogDropdownItemV2 {
  min-width: 150px;
  line-height: 24px;
  white-space: nowrap;
  box-shadow: none;
}

.KogDropdownItemV2--divider {
  border-bottom: 1px solid var(--kog-dropdown-divider);
}

.KogDropdownItemV2-option {
  padding: var(--space-xxs) var(--space-m);
  box-shadow: none;
}

.KogDropdownItemV2-option--selectable {
  cursor: pointer;
}

.KogDropdownItemV2-option--withChildOptions {
  padding-right: var(--space-xs);
}

.KogDropdownItemV2--option--selectable:focus-within,
.KogDropdownItemV2--menuitem--selectable.KogDropdownItemV2--checkbox:focus-within,
.KogDropdownItemV2--menuitem--selectable.KogDropdownItemV2--radio:focus-within {
  background: var(--kog-dropdown-item-focus-background);
}

.KogDropdownItemV2--menuitem--selectable.KogDropdownItemV2--text:focus-within {
  color: var(--kog-dropdown-menu-item-hover-color);
  background: var(--kog-dropdown-menu-item-hover-background);
}

.KogDropdownItemV2--selected {
  background: var(--kog-dropdown-item-selected-background);
}

.KogDropdownItemV2--groupTitle {
  height: var(--space-xl);
  padding-top: 0;
  padding-bottom: 0;
  color: var(--kog-dropdown-item-group-title-color);
}

.KogDropdownItemV2-separator {
  margin: var(--space-xxs) var(--space-xs) var(--space-xxs) 0;
  border-color: var(--kog-dropdown-item-separator-color);
  border-style: solid;
  border-width: 0 0 0 1px;
}

.KogDropdownItemV2--disabled {
  cursor: not-allowed;
  color: var(--kog-dropdown-item-disabled-color);
}
</style>
