<template>
  <dropdown
    ref="rootRef"
    theme="kog-dropdown"
    :shown="isExpanded"
    :auto-hide="false"
    :triggers="[]"
    boundary="document.body"
    :container="isHostedInParentContainer ? false : 'body'"
    :placement="placement"
    auto-size="min"
  >
    <div ref="triggerRef">
      <slot name="dropdownTrigger" />
    </div>
    <template #popper>
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <div
        v-if="isExpanded"
        ref="container"
        v-on-click-outside="clickOutsideHandler"
        @focusout="$emit('focusout', $event)"
        @keydown.tab.capture.stop.prevent
        @keydown.esc.stop="close"
      >
        <kog-radio-group
          v-if="dropdownItemType === 'radio'"
          ref="kogItemList"
          class="KogDropdownV2-list KogDropdownV2-radioGroup"
          :options="items"
          :selected-value="selectedItem ? selectedItem.value : ''"
          :auto-focus="true"
          @custom-option-value-change="onCustomOptionValueChanged"
          @checked="onRadioItemSelect"
        />
        <kog-item-list
          v-else
          ref="kogItemList"
          class="KogDropdownV2-list"
          role="listbox"
          :auto-focus="true"
          :is-search-by-typing-enabled="true"
          :focusable-elements-selector="
            dropdownItemType === 'text' ? '.KogDropdownItemV2-option' : ''
          "
        >
          <kog-dropdown-item-v2
            v-for="item in items"
            :key="JSON.stringify(item.value)"
            :dropdown-item-type="dropdownItemType"
            :item="item"
            :role="itemRole"
            :selected-item="selectedItem"
            :is-selected="
              selectedItem && !selectedItem.disabled && selectedItem.value === item.value
            "
            @select="onItemSelect"
          />
        </kog-item-list>
      </div>
    </template>
  </dropdown>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
import { ref } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { Dropdown } from 'floating-vue';

import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import { getMaxZIndexFromAncestors } from 'sharedApp/utils/dom-utils.js';

import KogDropdownItemV2 from './kog-dropdown-item-v2.vue';

export default {
  name: 'KogDropdownV2',
  components: {
    KogDropdownItemV2,
    KogItemList,
    KogRadioGroup,
    Dropdown,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    isHostedInParentContainer: {
      type: Boolean,
      default: false,
    },
    itemRole: {
      type: String,
      default: 'option',
      validator: prop => ['option', 'menuitem'].includes(prop),
    },
    dropdownItemType: {
      type: String,
      default: 'text',
      validator: prop => ['text', 'radio', 'checkbox'].includes(prop),
    },
  },
  emits: ['custom-option-value-change', 'item-select', 'close', 'focusout'],
  setup(props, { emit }) {
    const triggerRef = ref();
    const rootRef = ref();

    const close = () => {
      emit('close');
    };

    const clickOutsideHandler = [
      event => {
        const dropdownZIndex = getMaxZIndexFromAncestors(rootRef);
        const targetElementZIndex = getMaxZIndexFromAncestors(event.target);

        if (targetElementZIndex > dropdownZIndex) {
          return;
        }
        if (props.isExpanded) {
          close();
        }
      },
      {
        ignore: [triggerRef],
      },
    ];

    return {
      close,
      rootRef,
      triggerRef,
      clickOutsideHandler,
    };
  },
  methods: {
    onCustomOptionValueChanged(option) {
      this.$emit('custom-option-value-change', option);
    },
    onRadioItemSelect(value) {
      this.$emit('item-select', { value });
    },
    onItemSelect(item) {
      this.$emit('item-select', item);
    },
  },
};
</script>

<style scoped>
.KogDropdownV2-list {
  overflow-y: auto;

  min-width: 100%;
  max-height: calc(7.5 * var(--space-xl) + var(--space-s));
  margin: 0;
  padding: var(--space-xs) 0;

  font-size: var(--kog-font-size-default);

  background-color: var(--kog-dropdown-background-color);
  border: 1px solid var(--kog-dropdown-border-color);
  border-radius: var(--space-xs);
}

.KogDropdownV2-radioGroup {
  padding: var(--space-xs) var(--space-m) var(--space-l) var(--space-xs);
}

.KogDropdownV2-radioGroup :deep(.KogRadioGroup--groupTitle) {
  display: flex;
  padding: var(--space-xxs) var(--space-xs);
  color: var(--kog-dropdown-item-group-title-color);
}
</style>
