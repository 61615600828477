<template>
  <div
    :class="classAttr"
    :style="styleAttr"
  >
    <label
      v-if="label && !isLabelHidden"
      :id="getUniqueId('select-label')"
      :for="getUniqueId('select')"
      class="KogSelect-label heading-xxs"
      aria-hidden="true"
    >
      {{ label }}
      <span class="screenreader-only">:</span>
    </label>
    <kog-dropdown-v2
      :id="getUniqueId('dropdown')"
      :items="parsedOptions"
      item-role="option"
      :selected-item="selectedOption"
      :is-expanded="isExpanded"
      :is-hosted-in-parent-container="isDropdownHostedInParentContainer"
      :dropdown-item-type="dropdownItemType"
      @custom-option-value-change="onCustomOptionValueChanged"
      @item-select="onOptionSelected"
      @close="hide"
    >
      <template #dropdownTrigger>
        <button
          :id="getUniqueId('select')"
          ref="selectButton"
          class="KogSelect flexContainer flexContainer-alignCenter"
          :class="{
            'KogSelect--invalid': isInvalid,
          }"
          type="button"
          :disabled="isDisabled"
          aria-haspopup="listbox"
          :aria-controls="getUniqueId('dropdown')"
          :aria-describedby="getUniqueId('select-info')"
          :aria-expanded="isExpanded ? 'true' : 'false'"
          :aria-invalid="isInvalid ? 'true' : null"
          :aria-labelledby="getUniqueId('select-label')"
          :aria-label="isLabelHidden ? label : ''"
          :aria-required="isRequired ? 'true' : null"
          :data-active-option="selectedOptionText"
          role="combobox"
          @click.prevent="toggleExpanded"
          @keydown.space.prevent="toggleExpanded"
        >
          <kog-icon
            v-if="icon"
            class="margin-right-xs"
            :icon-class="icon"
            fa-style="regular"
            size="s"
          />
          <kog-text-trim
            class="flexChild-size-1 text-left"
            :text="selectedOptionText"
            :max-lines="1"
          />
          <span
            class="KogSelect-dropdownCaret fas fa-caret-down margin-left-xs"
            :class="{
              'point-up': isExpanded,
            }"
          />
        </button>
      </template>
    </kog-dropdown-v2>
    <select
      v-bind="otherAttrs"
      class="KogSelect--native"
      :value="selectedValue"
    >
      <option
        v-for="option in parsedOptions"
        :key="JSON.stringify(option.value)"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
    <div
      :id="getUniqueId('select-info')"
      :aria-label="validationMessage || helperText"
    >
      <span
        v-if="validationMessage"
        role="alert"
        class="KogSelect-error"
      >
        {{ validationMessage }}
      </span>
      <span
        v-else-if="helperText"
        class="KogSelect-helper"
      >
        {{ helperText }}
      </span>
    </div>
  </div>
</template>

<script>
import KogTextTrim from 'sharedApp/components/base/utils/kog-text-trim.vue';
import KogDropdownV2 from 'sharedApp/components/dropdown/kog-dropdown-v2.vue';
import KogIcon from 'sharedApp/components/icons/kog-icon.vue';
import useStyleClassExtractedAttrs from 'sharedApp/composables/use-style-class-extracted-attrs.ts';
import useUniqueId from 'sharedApp/composables/use-unique-id.ts';

const REQUIRED_FIELD_VALIDATION_MESSAGE = 'Please select an item in the list.';

export default {
  name: 'KogSelect',
  components: {
    KogDropdownV2,
    KogIcon,
    KogTextTrim,
  },
  inheritAttrs: false,
  props: {
    /**
     * The label should always be specified, for accessibility purposes.
     * If you don't want it to be visible, use `isLabelHidden: true`
     */
    label: {
      type: String,
      required: true,
    },
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * A description or additional information to be displayed below the select
     */
    helperText: {
      type: String,
      default: '',
    },
    /**
     * The list of options and option-groups to show in the dropdown.<br>
     * Structure of an **option** item:<br>
     * - `text: String`<br>
     * - `value: Object | String | Number`<br>
     * - `iconClass: String` (optional)<br>
     * - `disabled: Boolean` (optional)<br>
     *
     * Structure of an **option-group** item:<br>
     * - `text: String` the group name<br>
     * - `options: Array<option>`
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * The value of the currently selected option.
     */
    selectedValue: {
      type: [Object, String, Number],
      default: null,
    },
    /**
     * Fontawesome class for icon to show left-aligned in the select box
     */
    icon: {
      type: String,
      default: '',
    },
    /**
     * Set to true if you want the popover to be rendered inside the parent container.
     * By default, it's rendered in the document body.
     */
    isDropdownHostedInParentContainer: {
      type: Boolean,
      default: false,
    },
    /**
     * Type for the options to be either text, radio buttons or checkboxes
     */
    dropdownItemType: {
      type: String,
      default: 'text',
      validator: prop => ['text', 'radio', 'checkbox'].includes(prop),
    },
    /**
     * Text to be displayed when no value has been selected
     */
    placeholderText: {
      type: String,
      default: '',
    },
  },
  emits: ['custom-option-value-change', 'change', 'update:selectedValue'],
  setup() {
    const { getUniqueId } = useUniqueId();
    const extractedAttrs = useStyleClassExtractedAttrs();

    return {
      getUniqueId,
      ...extractedAttrs,
    };
  },
  data() {
    return {
      isExpanded: false,
      showValidationMessage: false,
    };
  },
  computed: {
    parsedOptions() {
      const parsedOptions = [];
      if (!this.options || this.options.length === 0) {
        return parsedOptions;
      }

      this.options.forEach(option => {
        parsedOptions.push(...this.getParsedOptions(option));
      });

      return parsedOptions;
    },
    selectedOption() {
      if (this.parsedOptions.length === 0) {
        return null;
      }

      const options = [...this.parsedOptions];
      const selectedOptions = [];

      for (let i = 0; i < options.length; i += 1) {
        const option = options[i];
        const isSelectedOption =
          option.value === this.selectedValue || option.customEditorValue === this.selectedValue;
        if (isSelectedOption) {
          selectedOptions.push(option);
        }

        const hasChildOptions = option.options?.length > 0;
        if (hasChildOptions) {
          options.splice(i + 1, 0, ...option.options);
        }
      }

      return selectedOptions.length > 0 ? selectedOptions[0] : null;
    },
    selectedOptionText() {
      if (this.isCheckbox) {
        const selectedOptions = this.parsedOptions
          .filter(option => option.isChecked === true)
          .map(option => option.text);

        return selectedOptions.join(', ') || this.placeholderText;
      }

      if (!this.selectedOption) {
        return this.placeholderText;
      }

      if (this.selectedOption.customEditor) {
        return this.selectedOption.customEditorValue;
      }

      return this.selectedOption.text;
    },
    isDisabled() {
      return this.$attrs.disabled === true;
    },
    isInvalid() {
      return !!this.validationMessage;
    },
    isRequired() {
      return Object.prototype.hasOwnProperty.call(this.$attrs, 'required');
    },
    validationMessage() {
      if (this.isRequired && this.showValidationMessage) {
        const hasFalsySelectedValue = [null, undefined, ''].includes(this.selectedValue);
        const doesSelectedValueExist = this.options.some(opt => opt.value === this.selectedValue);
        return hasFalsySelectedValue || !doesSelectedValueExist
          ? REQUIRED_FIELD_VALIDATION_MESSAGE
          : null;
      }

      return null;
    },
    isCheckbox() {
      return this.dropdownItemType === 'checkbox';
    },
    isClosedOnClick() {
      return !this.isCheckbox;
    },
  },
  methods: {
    getParsedOptions(option) {
      const parsedOptions = [];
      const hasValue = ['object', 'string', 'number'].includes(typeof option.value);
      const hasChildOptions = option.options && option.options.length > 0;

      if (hasChildOptions) {
        if (hasValue) {
          const parentOption = {
            ...option,
            options: [],
          };

          option.options.forEach(childOption => {
            parentOption.options.push(...this.getParsedOptions(childOption));
          });

          parsedOptions.push(parentOption);
        } else {
          // option-group item
          parsedOptions.push({
            text: option.text,
            isGroupTitle: true,
          });

          option.options.forEach(childOption => {
            parsedOptions.push(...this.getParsedOptions(childOption));
          });
        }
      } else {
        parsedOptions.push({ ...option });
      }

      return parsedOptions;
    },
    toggleExpanded() {
      if (this.isExpanded) {
        this.hide();
        return;
      }

      this.isExpanded = true;
    },
    hide() {
      if (this.isExpanded) {
        this.isExpanded = false;
        this.$refs.selectButton.focus();
      }
    },
    onCustomOptionValueChanged(option) {
      this.$emit('custom-option-value-change', option);
    },
    onOptionSelected(option) {
      this.$emit('update:selectedValue', option.value);
      this.$emit('change', option.value);
      if (this.isClosedOnClick) {
        this.hide();
      }
      this.showValidationMessage = true;
    },
  },
};
</script>

<style scoped>
.KogSelect--native {
  display: none;
}

.KogSelect-label {
  cursor: default;
  display: inline-block;
  margin: 0 0 var(--space-xxs);
}

.KogSelect {
  width: 100%;
  min-height: 40px;
  padding: var(--space-xs) var(--space-s);

  font-weight: normal;

  background-color: var(--kog-select-background-color);
  border-color: var(--kog-select-border-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
}

.KogSelect-dropdownCaret {
  transition: transform 0.15s ease-in-out;
}

.KogSelect-dropdownCaret.point-up {
  transform: rotate(180deg);
}

.KogSelect:disabled {
  background-color: var(--kog-select-disabled-background-color);
}

.KogSelect:focus:not(.KogSelect--invalid),
.KogSelect:focus-visible:not(.KogSelect--invalid) {
  border-color: var(--kog-select-focus-border-color);
  outline: none;
  box-shadow: none;
}

.KogSelect-helper,
.KogSelect-error {
  display: inline-block;
  margin-top: var(--space-xxs);
  font-size: 12px;
  line-height: 24px;
}

.KogSelect-helper {
  color: var(--kog-select-helper-text-color);
}

.KogSelect-error {
  color: var(--kog-select-error-text-color);
}

.KogSelect--invalid {
  border-color: var(--kog-select-error-border-color) !important;
}

.KogSelect:hover:not(:disabled):not(:focus) {
  border-color: var(--kog-select-hover-border-color);
}
</style>
